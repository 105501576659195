import ru from './ru.json';
import kk from './kk.json';
import qq from './qq.json';
import en from './en.json';

const locales = {
  ru: { ...ru },
  kk: { ...kk },
  qq: { ...qq },
  en: { ...en }
};

export default locales;

import axios from 'axios';
import Cookies from 'universal-cookie';
import store from './store';
const cookies = new Cookies();

const axiosInterceptorsConfig = () => {
  axios.interceptors.request.use(config => {
    store.dispatch({ type: 'LOADING', payload: true });
    const token = cookies.get('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    store.dispatch({ type: 'LOADING', payload: false });
    return response;
  }, error => {
    store.dispatch({ type: 'LOADING', payload: false });
    return Promise.reject(error);
  });
};

export default axiosInterceptorsConfig;